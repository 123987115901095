import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { toDate, endOfDay } from '@/utils/date'

export default {
  async selectFilterLookups (Vue) {
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({ name: 'selectToperacioncrm', method: 'toperacioncrm.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectEmpleado', method: 'empleado.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },

  async selectOperacionCRM (Vue, filter, search, sorter, page, idempleado, abierta, asignada, campoFK, identificador, pageSize) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
    if (campoFK) {
      apiFilter.addExact(campoFK, identificador)
    }
    if (abierta) {
      apiFilter.addExact(filter.abierta.field, abierta)
    }
    if (asignada) {
      apiFilter.addExact(filter.asignada.field, idempleado)
    }
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.operacioncrm.searchFilter(search))
    }
    if (filter.descripcion.value) {
      apiFilter.addILike(filter.descripcion.field, filter.descripcion.value)
    }
    if (filter.abierta.value !== null ) {
      apiFilter.addExact(filter.abierta.field, filter.abierta.value)
    }
    if (filter.asignada.value) {
      apiFilter.addExact(filter.asignada.field, idempleado)
    }
    if (filter.informada.value) {
      apiFilter.addExact(filter.informada.field, idempleado)
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    if (filter.toperacioncrm.value) {
      apiFilter.addExact(filter.toperacioncrm.field, filter.toperacioncrm.value)
    }
    if (filter.responsable.value) {
      apiFilter.addExact(filter.responsable.field, filter.responsable.value)
    }
    if (filter.informador.value) {
      apiFilter.addExact(filter.informador.field, filter.informador.value)
    }
    const resp = await Vue.$api.call('operacioncrm.select', {
      page,
      filter: apiFilter,
      sorter,
      page_size: pageSize,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectOperacionCRMRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idoperacioncrm', pks)
    const resp = await Vue.$api.call('operacioncrm.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
