<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron operaciones"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :toolbar-options="toolbarOptions"
      :page-loaded="pageLoaded"
      pk-name="idoperacioncrm"
      @open-filter="openFilter"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
    >
      <template
        #listItem="slotProps"
      >
        <operacioncrm-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :idempleado="usuarioIdempleado"
          :has-perm-borrar="hasDeletePerm($route.meta.permission.idobjeto)"
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
    <operacioncrm-list-legend
      :showing.sync="showingDialogs.legends"
    />
    <b10-fab-button
      v-if="hasInsertPerm($route.meta.permission.idobjeto)"
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './OperacioncrmListData'
import OperacioncrmListItem from './components/OperacioncrmListItem'
import OperacioncrmListLegend from './components/OperacioncrmListLegend'
import { get } from 'vuex-pathify'
import { TABLA } from '@/utils/consts'

const pageStoreName = 'pagesOperacioncrmList'

export default {
  components: {
    OperacioncrmListItem, OperacioncrmListLegend,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    abierta: {
      type: Boolean,
    },
    asignada: {
      type: Boolean,
    },
    addRoute: {
      type: String,
      default: ''
    },
    viewRoute: {
      type: String,
      default: '',
    },
    tabla: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        mapMarkerInfo: false,
        map: false,
        legends: false,
      },
      mapMarkerInfo: {
        title: '',
        subtitle: '',
        lat: null,
        lng: null,
      },
      mapMarkers: [],
      TABLA,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    nextRoute: get(`${pageStoreName}/nextRoute`),
    usuarioIdempleado: get('usuario/idempleado'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Operaciones'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        let identificador = this.$online.operacioncrm.getIdentificador(this.tabla, this.routeParams)
        const [dataset, metadata] = await Data.selectOperacionCRM(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.usuarioIdempleado,
          this.abierta,
          this.asignada,
          this.tabla?.pk,
          identificador,
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectOperacionCRMRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'idoperacioncrm')
      } finally {
        this.loadingData = false
      }
    },
    async clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: this.viewRoute,
        params: {
          idoperacioncrm: item.data.idoperacioncrm,
          idcliente: this.routeParams.idcliente
        },
      })
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      }
    },
    async openFilter () {
      // en evento openFilter y no en propiedad initFilter para no cargar
      // datos de los select hasta que no se abre el dialog del filtro
      if (
        this.filter.toperacioncrm.items.length === 0 &&
        this.filter.responsable.items.length === 0 &&
        this.filter.informador.items.length === 0
      ) {
        const resp = await Data.selectFilterLookups(this)
        await this.setStoreProperty('filter@toperacioncrm.items', resp.data.selectToperacioncrm.result.dataset)
        await this.setStoreProperty('filter@responsable.items', resp.data.selectEmpleado.result.dataset)
        await this.setStoreProperty('filter@informador.items', resp.data.selectEmpleado.result.dataset)
      }
    },
    async clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: this.addRoute,
        params: {
          idusuario: this.usuarioIdusuario,
          idcliente_potencial: this.routeParams.idcliente_potencial,
          idcliente: this.routeParams.idcliente,
          idsistema: this.routeParams.idsistema,
          idpresupuestocli: this.routeParams.idpresupuestocli,
        },
      })
    },
    async clickDelete (data) {
      const res = await this.$alert.showConfirm(`¿Deseas eliminar la operación "${data.item.serie_numero}"`)
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await this.$online.operacioncrm.delete(data.item.idoperacioncrm)
          await this.dispatchStore('deleteItem', data.index)
        } finally {
          this.$loading.hide()
          this.$alert.showSnackbarSuccess(`Se ha eliminado la operación "${data.item.serie_numero}"`)
        }
      }
    },
  },
}
</script>
